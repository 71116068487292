//wkb banner slider
var $wkb_gallery = $(document).find('.wkb-gallery');
if( $wkb_gallery.length > 0 ) {
  $.each($wkb_gallery, function( index, gallery ) {
    var $gallery = $(gallery);
    var $slides = $('.owl-carousel', $gallery);
    var columns = $gallery.data('columns');
    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        items: 1,
        dots: false,
        nav: true,
        loop: false,
        margin: 16,
        responsive : {
          // breakpoint from 480 up
          480 : {
            items: 2,
          },
          // breakpoint from 768 up
          768 : {
            items: columns,
          }
      }
      });
    }
  });
}
